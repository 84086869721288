import { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const CustomAlert = () => {
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 10000); // 10s

    return () => clearTimeout(timer);
  }, []);

  return (
    <Stack
      sx={{
        width: "100%",
        position: "fixed",
        top: 0,
        right: 0,
        zIndex: 100,
      }}
      spacing={2}
    >
      {showAlert && (
        <Alert severity="warning" onClose={() => setShowAlert(false)}>
          nftOS is currently in beta on the sepolia testnet, it has ERC-1155
          token and MetaMask support
        </Alert>
      )}
    </Stack>
  );
};

export default CustomAlert;
