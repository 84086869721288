import Image from "next/image";
import Link from "next/link";

const HeroLanding = () => {
  return (
    <section className="hero flex flex-col items-center justify-center bg-cover bg-center bg-no-repeat min-h-screen pb-[150px]">
      <div className="text-center ">
        <div className="-mt-16 flex items-center justify-center w-full">
          <Image
            src="/images/1.png"
            alt="logo"
            width={350}
            height={350}
            className="w-[350px] h-auto"
          />
        </div>
        <div className="-mt-16">
          <h1 className="text-4xl font-semibold pt-4 text-white">
            World's First
          </h1>
          <h1 className="text-6xl font-bold text-white">
            Congolomerate NFTs Marketplace
          </h1>

          <div className="flex mt-8 justify-center">
            <Link href="/login" className="cursor-pointer">
              <button className="btn-grad text-white transition-all px-6 py-3 rounded-full cursor-pointer">
                Get Started
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroLanding;
