export const hero_6_data = [
  {
    id: "0Amazing NFT art",
    nftImage: "/images/products/item_14_square.jpg",
    nftName: "Amazing NFT art",
    creatorWallet: "by NFT stars",
  },
  {
    id: "1Amazing NFT art",
    nftImage: "/images/products/item_27_square.jpg",
    nftName: "Amazing NFT art",
    creatorWallet: "by Lazy Panda",
  },
  {
    id: "2Punkie Cat",
    nftImage: "/images/products/item_28_square.jpg",
    nftName: "Punkie Cat",
    creatorWallet: "by NFT stars",
  },
  {
    id: "3VR Space_283",
    nftImage: "/images/products/item_29_square.jpg",
    nftName: "VR Space_283",
    creatorWallet: "by Origin Morish",
  },
  {
    id: "4TSARÉVNA",
    nftImage: "/images/products/item_20_square.jpg",
    nftName: "TSARÉVNA",
    creatorWallet: "CryptoGuysNFT",
  },
  {
    id: "5Metasmorf",
    nftImage: "/images/products/item_30_square.jpg",
    nftName: "Metasmorf",
    creatorWallet: "by Lazy Panda",
  },
  {
    id: "6VR Space_286",
    nftImage: "/images/products/item_25.jpg",
    nftName: "VR Space_286",
    creatorWallet: "by Origin Morish",
  },
  {
    id: "7PankySkal",
    nftImage: "/images/products/item_31_square.jpg",
    nftName: "PankySkal",
    creatorWallet: "by NFT stars",
  },
  {
    id: "8VR Space_287",
    nftImage: "/images/products/item_32_square.jpg",
    nftName: "VR Space_287",
    creatorWallet: "by Origin Morish",
  },
];
