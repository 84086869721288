import { useRouter } from "next/router";
import pathChecking from "../../utils/pathChecking";
import Testimonial_carousel from "../carousel/testimonial_carousel";

const Testimonial = ({ bg_jacerta }) => {
  const route = useRouter();

  return (
    <div>
      {/* <!-- Testimonials --> */}
      <section
        className={
          bg_jacerta
            ? "testimonial-section py-24 "
            : `testimonial-section ${
                pathChecking(route.asPath, "/home/home_10") ? "" : ""
              } py-24 `
        }
      >
        <div className="container relative overflow-hidden xl:left-[calc((100vw-1202px)/4)] xl:max-w-[calc(1202px+((100vw-1202px)/2))] xl:pr-[calc((100vw-1176px)/2)]">
          <div className="mx-auto mb-12 max-w-sm text-center">
            <h2 className="mb-6 text-center font-display text-3xl font-medium text-jacarta-900 ">
              Satisfied Customers
            </h2>
          </div>

          <Testimonial_carousel />
        </div>
      </section>
      {/* <!-- end testimonials --> */}
    </div>
  );
};

export default Testimonial;
