import { motion } from "framer-motion";

const AnimationPoints = ({ title, desc1, desc2, imgUrl }) => {
  return (
    <div className="max-w-[1280px] mx-auto my-12">
      <div className="flex md:pl-7 space-x-3 md:space-x-10">
        <div className="flex flex-col items-center pt-2 mr-3">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            viewport={{ once: false }}
            className="relative"
          >
            <img
              className="w-[50px] h-auto absolute left-0 top-0 z-[1]"
              width="44"
              height="44"
              loading="lazy"
              decoding="async"
              alt={title}
              aria-hidden="true"
              src={imgUrl}
            />
            <div className="w-[50px] h-[50px]"></div>

            <span className="absolute left-0 top-0 h-full w-full home-campaign-glowing-icon-glow-1 z-[1]"></span>
          </motion.div>
          <motion.div
            initial={{ height: 0 }}
            whileInView={{ height: "100%" }}
            transition={{ duration: 0.4, delay: 0.6 }}
            viewport={{ once: false }}
            className=" h-full w-[3px] mt-7 rounded-md bg-gradient-to-b from-[#A11011]"
          ></motion.div>
        </div>
        <div className="md:w-10/12 mb-12">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.3 }}
            viewport={{ once: false }}
            className="text-[20px] md:text-2xl mb-7 font-medium text-white js-build-in-item build-in-slideX-left build-in-animate"
            style={{ transitionDelay: "200ms" }}
          >
            <div className="border-[1px] border-neutral-600 copilot rounded-full inline-block">
              <div className="flex items-center p-3 px-5 ">
                <div className="mr-4 capitalize">{title}</div>
              </div>
            </div>
          </motion.div>
          <motion.h3
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.3 }}
            viewport={{ once: false }}
            className="text-[28px] md:text-[35px] max-md:leading-8 max-lg:leading-10 lg:text-5xl mb-7 font-medium text-white js-build-in-item build-in-slideX-left build-in-animate"
            style={{ transitionDelay: "300ms" }}
          >
            <span className="text-[#A11011] font-bold">{desc1}</span>
            {desc2}
          </motion.h3>
        </div>
      </div>
    </div>
  );
};

export default AnimationPoints;
