/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useState, useRef } from "react";
import Meta from "../Meta";
import Hero_landing from "../hero/hero_landing";
import Benifits from "../dao/Benifits";
import Testimonial from "../testimonial/Testimonial";
import Process from "../blog/process";
import Participate from "../dao/Participate";
import NewseLatter2 from "../dao/newseLatter2";
import Services from "../services/services";
import Link from "next/link";
import AnimationPoints from "./AnimationPoints";
import Herolanding2 from "./Herolanding2";
import Image from "next/image";
import Alert from "../alert";
import { motion } from "framer-motion";

const Landing = () => {
  return (
    <div className="">
      <Meta title="home" />
      <Image
        src="/giphy.webp"
        alt="backgroundGif"
        width={500}
        height={500}
        className="fixed top-0 left-0 w-full h-[100vh] z-[-1] object-cover"
        priority
      />

      <div>
        <Alert />
        {/* <Hero_landing /> */}
        <div className="overflow-hidden pb-24">
          <div className="hero-section px-3 ">
            <Herolanding2 />
          </div>
          <div
            id="Commission"
            className="home-campaign-productivity px-4 pt-8 overflow-hidden"
          >
            <AnimationPoints
              title="0 Commission"
              desc1={
                "Join our revolutionary NFTs marketplace where you keep every dollar earned"
              }
              desc2={" — no commission fees, just pure profits and freedom"}
              imgUrl="/images/ico-landing/comission.png"
            />
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.4, delay: 0.6 }}
            viewport={{ once: false }}
            style={{ transitionDelay: "300ms" }}
          >
            <Image
              src="/images/ico-landing/commision.svg"
              alt="A gif of a person dancing"
              width={400}
              height={400}
              className=" object-cover mx-auto w-[300px] md:w-[450px] h-auto"
            />
          </motion.div>
          <div
            id="Onedestination"
            className="home-campaign-productivity px-4 pt-8 overflow-hidden"
          >
            <AnimationPoints
              title="One destination"
              desc1={
                "Explore a single platform offering access to multiple NFTs market,"
              }
              desc2={
                " bringing diverse opportunities and seamless experiences all in one place"
              }
              imgUrl="/images/ico-landing/destination.png"
            />
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.4, delay: 0.6 }}
            viewport={{ once: false }}
            style={{ transitionDelay: "300ms" }}
          >
            <Image
              src="/images/ico-landing/destination.svg"
              alt="A gif of a person dancing"
              width={400}
              height={400}
              className=" object-cover mx-auto w-[300px] md:w-[450px] h-auto"
            />
          </motion.div>
          <div
            id="Innovative"
            className="home-campaign-productivity px-4 pt-8 overflow-hidden"
          >
            <AnimationPoints
              title="Innovative Features"
              desc1={
                "Discover our new token designed with real-world features, "
              }
              desc2={
                "offering practical applications, seamless integration, and enhanced utility for everyday transactions"
              }
              imgUrl="/images/ico-landing/innovation.png"
            />
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.4, delay: 0.6 }}
            viewport={{ once: false }}
            style={{ transitionDelay: "300ms" }}
          >
            <Image
              src="/images/ico-landing/innovative.svg"
              alt="A gif of a person dancing"
              width={400}
              height={400}
              className=" object-cover mx-auto w-[300px] md:w-[450px] h-auto"
            />
          </motion.div>
          <div
            id="FastEasy"
            className="home-campaign-productivity px-4 pt-8 overflow-hidden"
          >
            <AnimationPoints
              title="Fast and easy"
              desc1={"Our platform features a user-friendly interface,"}
              desc2={
                " ensuring quick navigation and effortless transactions for an optimal NFT experience"
              }
              imgUrl="/images/ico-landing/fast-easy.png"
            />
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.4, delay: 0.6 }}
            viewport={{ once: false }}
            style={{ transitionDelay: "300ms" }}
          >
            <Image
              src="/images/ico-landing/fast.png"
              alt="A gif of a person dancing"
              width={400}
              height={400}
              className=" object-cover mx-auto w-[300px] md:w-[450px] h-auto"
            />
          </motion.div>
          <div
            id="WideVariety"
            className="home-campaign-productivity px-4 pt-8 overflow-hidden"
          >
            <AnimationPoints
              title="Wide Variety"
              desc1={"Our marketplace features a wide range of NFT categories,"}
              desc2={
                " offering countless options for collectors and creators to explore"
              }
              imgUrl="/images/ico-landing/wide-variety.png"
            />
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.4, delay: 0.6 }}
            viewport={{ once: false }}
            style={{ transitionDelay: "300ms" }}
          >
            <Image
              src="/images/ico-landing/wide.svg"
              alt="A gif of a person dancing"
              width={400}
              height={400}
              className=" object-cover mx-auto w-[300px] md:w-[450px] h-auto"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.3 }}
            viewport={{ once: false }}
            id="WideVariety"
            className="font-monserrat home-campaign-productivity px-4 overflow-hidden  max-w-[1100px] text-[#ffffff] text-center w-full font-bold text-5xl md:text-[3.2rem] italic h-[60svh] md:h-[90svh] flex items-center justify-center mx-auto"
          >
            Embrace the revolution and unlock the full potential of your digital
            assets with us!
          </motion.div>

          <div className="h-[50vh] md:h-[70vh] flex flex-col mx-auto px-8 max-w-[1200px] justify-center items-center">
            <div className="flex flex-col items-center justify-center border copilot border-[#141414] p-8 md:px-28 md:py-8 gap-3 rounded-full">
              <Image
                src="/favicon.png"
                alt="Picture of the author"
                width={100}
                height={100}
                className="w-[75px] md:w-[100px] h-auto"
              />
              <p className="text-2xl md:text-5xl font-bold">Start With nftOS</p>
              <p className="text-center text-md md:text-lg text-[#7d8590] mb-6">
                World's First Conglomerate NFTs Marketplace
              </p>
              <Link href="/login" className="cursor-pointer">
                <button className="btn-grad text-white transition-all px-6 py-3 rounded-full cursor-pointer">
                  Get Started
                </button>
              </Link>
            </div>
          </div>
        </div>
        <footer className="flex w-full justify-between border-t border-[#383838] px-4 py-8 pt-4 items-center">
          <ul className="text-white flex flex-wrap space-x-4 text-sm">
            <li>
              <Link href="/tarms" className="">
                © 2024 Trillicorn Technology
              </Link>
            </li>
          </ul>
          <ul className="flex gap-3 invert">
            <a href="https://x.com/nftOS2024">
              <Image
                width={120}
                height={50}
                src="/twitter.svg"
                alt=""
                className="text-[#686868] w-6 h-auto"
              />
            </a>
            <a href="https://www.linkedin.com/showcase/nftos-marketplace/">
              <Image
                width={120}
                height={50}
                src="/linkedin.svg"
                alt=""
                className="w-6 h-auto"
              />
            </a>
          </ul>
        </footer>
      </div>
    </div>
  );
};

export default Landing;
// {
/*
      <div className="form-wrapper min-h-screen flex justify-center items-center bg-cover bg-center">
        <div className="form-container backdrop-blur-lg w-screen h-screen lg:w-[80vw] lg:h-[90vh] p-8 lg:p-20 rounded-2xl  text-[#1A2421] bg-gradient-to-b from-white/60 to-white/30  border-[1px] border-solid border-white border-opacity-30 shadow-black/70 shadow-2xl flex items-center justify-center flex-col">
          <div className="max-w-md flex items-center justify-center flex-col">
            <h1 className="text-3xl  lg:text-5xl font-extrabold mb-8">
              Don&apos;t Wait. Become Our Member Today.
            </h1>
            <button className=" hover:bg-accent transition-all text-white px-4 py-1 rounded-md hover:border-white hover:border-[1px] underline hover:no-underline">
              Get started
            </button>
          </div>
        </div>
      </div> */
// }
