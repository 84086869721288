import React, { useState, useEffect, useContext } from "react";
import Home_1 from "../components/landing/home";
import Landing from "../components/landing";
import Header01 from "../components/header/Header01";
import jwt from "jsonwebtoken";
// import Wallet_modal from "../components/modal/wallet_modal";
import UserContext from "../components/UserContext";
import throttle from "lodash.throttle";
import Image from "next/image";

export default function Home({ user }) {
  const { scrollRef } = useContext(UserContext);
  const [isAuthenticated, setIsAuthenticated] = useState(user ? true : false);
  const [showButton, setShowButton] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    if (user) {
      setIsAuthenticated(true);
    }
    window.scrollTo(0, scrollRef.current.scrollPos);
    const handleScrollPos = () => {
      scrollRef.current.scrollPos = window.scrollY;
    };
    window.addEventListener("scroll", handleScrollPos);
    return () => {
      window.removeEventListener("scroll", handleScrollPos);
    };
  }, [user, scrollRef]);

  useEffect(() => {
    const handleScroll = throttle(() => {
      if (window.scrollY > lastScrollY) {
        // Scrolling down
        setShowButton(false);
      } else {
        // Scrolling up
        setShowButton(true);
      }
      setLastScrollY(window.scrollY);
    }, 200); // Throttle the scroll event handler to run at most once every 200ms

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return isAuthenticated ? (
    <div className="bg-transparent" style={styles.container}>
      <Header01 user={user} />
      {/* <Wallet_modal /> */}

      <Home_1 user={user} />
      <button
        onClick={() => (window.location.href = "/CreateMain")}
        className={`create-button btn-grad font-semibold flex gap-2 items-center justify-center ${
          !showButton ? "hide" : ""
        }`}
      >
        <Image
          height={28}
          width={28}
          src="/images/hero/nft.png"
          alt="nft-icon"
          className="w-8 h-auto"
        />
        <p>Create</p>
      </button>
    </div>
  ) : (
    <Landing />
  );
}
const styles = {
  container: {
    backgroundImage: "url('/giphy.webp')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
  },
};

export async function getServerSideProps(context) {
  const { req } = context;
  const token = req.cookies.token;
  const user = token ? jwt.verify(token, process.env.JWT_SECRET) : null;

  return {
    props: { user }, // Will be passed to the page component as props
  };
}
