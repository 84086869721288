export const services_data = [
  {
    title: "0% Commission",
    desc: "Unlock zero commission trading in our marketplace. Empower creators and collectors with a fee-free experience—maximize returns on every transaction, fostering a thriving community of digital assets",
    id: "0NFT Consultant Analytics",
    icon: "service-ico-1",
    image: "/images/services/revenue.png",
  },
  {
    title: "Large Market",
    desc: "Diverse NFT products market: Product Gallery for visual arts, Property World for virtual property, and Food Shop for food NFTs. Explore unique creations and support creators across various domains.",
    id: "1NFT Marketing",
    icon: "service-ico-2",
    image: "/images/services/massmarket.png",
  },
  {
    title: "Fast and smooth",
    desc: "Experience a smooth and fast NFT marketplace—seamless transactions, quick listings. Unlock the potential of digital assets effortlessly. Elevate your NFT journey with us.",
    id: "2NFT Strategy",
    icon: "service-ico-3",
    image: "/images/services/coding.png",
  },

  {
    title: "Advanced Features",
    desc: "Our NFTs marketplace offers cutting-edge features, empowering creators and collectors with advanced tools for seamless transactions and unparalleled experiences.",
    id: "4Advisers & Intermediaries",
    icon: "service-ico-5",
    image: "/images/services/interactivity.png",
  },
];
