export const testimonial_data = [
  {
    title: "A Game-Changer for Artists and Investors",
    desc: "NFTOS offers a groundbreaking platform that caters to both creators and collectors alike. A must-try for anyone in the NFT space!",
    id: "0General Partner at Entrepreneur",
    name: "Katie Smith",
    img: "/images/testimonials/testimonial_1.jpg",
  },
  {
    title: "Where Creativity Meets Opportunity",
    desc: "From its diverse range of NFT offerings to its intuitive interface, NFTOS provides a fertile ground for artists to thrive and collectors to discover unique pieces. It's more than just a marketplace.",
    id: "1General Partner at Entrepreneur",
    name: "Katie Smith",
    img: "/images/testimonials/testimonial_2.jpg",
  },
  {
    title: "NFTOS Leads the Way",
    desc: "Whether you're a seasoned collector or a budding artist, NFTOS offers a seamless experience, making it easy to explore and invest in the world of NFTs.",
    id: "2General Partner at Entrepreneur",
    name: "Katie Smith",
    img: "/images/testimonials/testimonial_3.jpg",
  },
];
